const { jQuery: $, Profile, Modal, Pjax } = window

$.onmount(Modal.selector("person-update-status"), function () {
  $(this).on("modal:save", (_e, modal) => {
    // The update status modal is used in at least 3 places:
    // 1) The org chart, some coffee/etc other JS will reload the org chart
    // 2) The V2 profile page, where we want to go all the way through this
    //    profile-page-specific logic.
    // 3) The profile panel, which may be on any given page and should handle
    //    its own reload.
    const profilePanelContainer = $(".react-profile-panel")
    if (profilePanelContainer && profilePanelContainer.length > 0) return

    Profile.reloadProfileHeader(modal.$form).then(() => {
      const containerSelector = Pjax.targetContainer("person-job-tab")
      if (containerSelector) {
        $.pjax.reload(containerSelector, { fragment: containerSelector })
      }
    })
  })
})
